.mainBanner {
  background: url("./Assets/Images/main-banner.png");
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 500px;
  position: relative;
}

p {
  font-size: smaller;
}

.thumb {
  position: relative;
  margin-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.thumb img {
  top: 0;
  width: 100%;
  left: 0;
  z-index: 0;
}
.thumb h2 {
  position: absolute;
  color: #fff;
  z-index: 2;
  top: 35%;
  width: 100%;
  left: 0;
  text-align: center;
  font-weight: 300;
  text-transform: uppercase;
}
.thumb p {
  margin: 25px;
  margin-top: 40px;
  text-align: center;
}
.thumb a {
  color: #000;
  text-decoration: none;
  border: 2px solid #000;
  padding: 10px 20px;
  display: block;
  max-width: 200px;
  text-align: center;
  border-radius: 50px;
}
.thumb a:hover {
  background-color: #000;
  color: #fff;
}

.download-section {
  padding-top: 150px;
}

.page-footer {
  background: #222;
  width: 100%;
  margin-top: 300px;
  padding-bottom: 30px;
  position: relative;
}
.page-footer a {
  color: #000;
  text-decoration: none;
  border: 2px solid #000;
  padding: 10px 20px;
  display: block;
  max-width: 200px;
  text-align: center;
  border-radius: 50px;
  margin-top: 40px;
}
.page-footer a:hover {
  background-color: #000;
  color: #fff;
}
.page-footer img {
  margin-top: -300px;
  width: 100%;
}
.page-footer p {
  color: #fff;
  text-align: center;
  margin-top: 100px;
  font-weight: 200;
}
.page-footer p strong {
  font-weight: 500;
}
.page-footer .footer-title {
  position: absolute;
  z-index: 2;
  padding-left: 50px;
}
.page-footer h1 {
  margin-bottom: 30px;
}
.page-footer h4 {
  max-width: 400px;
  font-weight: 200;
}

@media (max-width: 768px) {
  .mainBanner {
    position: relative;
  }
  .mainBanner::before {
    content: "";
    background: rgba(255, 255, 255, 0.8);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
}/*# sourceMappingURL=App.css.map */