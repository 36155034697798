.Header-card {
  background: transparent;
  position: absolute;
  bottom: 20px;
  max-width: 500px;
  z-index: 2;
}
.Header-card h3 {
  font-weight: 300;
  font-size: 24px;
}
.Header-card p {
  margin-top: 20px;
  font-size: smaller;
} /*# sourceMappingURL=Card.css.map */
