.how-it-works-section {
  padding-top: 150px;
  padding-bottom: 150px;
}
.how-it-works-section article {
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 200px;
}

.how-steps {
  text-align: center;
}

.step-no {
  margin: auto;
  color: #179447;
  font-size: 40px;
  font-weight: 500;
  border: 4px solid #179447;
  width: 74px;
  height: 74px;
  border-radius: 40px;
  text-align: center;
  margin-bottom: 20px;
}

.stepp-vertical-line div {
  background-color: #000;
  width: 1px;
  height: 100%;
  margin: auto;
}

@media (max-width: 768px) {
  .how-it-works-section {
    text-align: center;
  }
  .how-it-works-section article {
    height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    max-width: 200px;
  }
}/*# sourceMappingURL=How.css.map */