.reports-section {
  background-color: #f9f9f9;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px;
}
.reports-section p {
  max-width: 450px;
  margin: auto;
  margin-top: 30px;
}/*# sourceMappingURL=Report.css.map */