.mainBanner .logo {
  width: 133px;
  margin-left: 15px;
  position: relative;
  z-index: 2;
}

.nav .nav-item a {
  color: #000;
  font-size: 12px;
  font-weight: 500;
  position: relative;
  z-index: 2;
}
.nav .nav-item a:hover {
  color: #555;
}

@media (max-width: 768px) {
  .mainBanner .logo {
    width: 133px !important;
    margin-left: 15px;
    position: relative;
    z-index: 2;
  }
}/*# sourceMappingURL=Navbar.css.map */