.Header-card {
  background: transparent;
  position: absolute;
  bottom: 20px;
  max-width: 500px;
  h3 {
    font-weight: 300;
    font-size: 24px;
  }
  p {
    margin-top: 20px;
    font-size: smaller;
  }
}
