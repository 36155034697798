.services-section {
  padding-top: 150px;
  padding-bottom: 150px;
  article {
    max-width: 700px;
    padding: 20px;
  }
}
.logo {
  width: 200px;
  margin: auto;
  display: block;
  position: relative;
  z-index: 2;
}
.contact-us-cta {
  color: #000;
  text-decoration: none;
  border: 2px solid #000;
  padding: 10px 20px;
  display: block;
  max-width: 200px;
  text-align: center;
  border-radius: 50px;
  margin-top: 24px;
  margin-left: 10px;
  &:hover {
    background-color: #000;
    color: #fff;
  }
}

@media (max-width: 768px) {
  .services-section {
    .logo {
      width: 180px;
    }
  }
  .services-section {
    padding-top: 0;
    article {
      text-align: center;
    }
  }
  .contact-us-cta {
    margin: auto;
    margin-top: 48px;
  }
}
